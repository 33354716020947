import React from "react";
import { Spinner as ReactBootstrapSpinner } from "react-bootstrap";
// import { TailSpin } from "react-loader-spinner";
import styled from "styled-components";

const Div = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const Spinner = (): JSX.Element => {
  return (
    <React.Fragment>
      <Div>
        {/* //* React Bootstrap Spinner testing */}
        {/* <TailSpin
          height="120"
          width="120"
          color="blue"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        /> */}
        <ReactBootstrapSpinner animation="border" variant="primary" />
      </Div>
    </React.Fragment>
  );
};

export default Spinner;
