import React from "react";
import styled from "styled-components";

import { appName, devices, logo, themeProviderCoMB } from "../../config";

const LogoDiv = styled.div`
  margin-right: 10px;
  width: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
`;

const Img = styled.img<{ $themeProviderCoMB: boolean }>`
  background-color: transparent;
  background: ${(props) => (props.$themeProviderCoMB ? "transparent" : "rgba(255, 255, 255, 0.4)")};
  width: auto;
  height: 75px;
`;

const H1 = styled.h1`
  font-size: 70%;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 1rem;
  @media only screen and ${devices.sm} {
    font-size: calc(1.375rem + 1.5vw);
  }
  @media only screen and ${devices.md} {
    font-size: 2.5rem;
  }
`;

const LogoContainer = (): JSX.Element => {
  return (
    <React.Fragment>
      <LogoDiv>
        <Img src={logo} alt="logo" $themeProviderCoMB={themeProviderCoMB} />
        <H1>{appName}</H1>
      </LogoDiv>
    </React.Fragment>
  );
};

export default LogoContainer;
