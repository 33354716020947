import icon33417 from "./icon33417.svg";
import icon33418 from "./icon33418.svg";
import icon33419 from "./icon33419.svg";
import icon33422 from "./icon33422.svg";
import icon67978 from "./icon67978.svg";
import iconNotMonitored from "./iconNotMonitored.svg";

const CoMBiconsSVG = { icon33417, icon33418, icon33419, icon33422, icon67978, iconNotMonitored };

export default CoMBiconsSVG;
